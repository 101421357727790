var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-12 order-1 order-xxl-1"},[_c('router-link',{attrs:{"to":"/myCompany/users/add","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('b-button',{staticClass:"mt-3 mb-4",attrs:{"href":href,"variant":"primary"}},[_vm._v("Dodaj użytkownika")])]}}])}),_c('div',{staticClass:"table-responsive"},[_c('v-data-table',{staticClass:"table custom-table table-head-custom table-vertical-center table-head-bg table-borderless",attrs:{"dense":"","headers":this.headers,"items":this.users,"item-key":"id","mobile-breakpoint":"0","footer-props":{
          showFirstLastPage: true,
          itemsPerPageText: 'Wyników na stronę',
          pageText: '{0}-{1} na {2}',
          itemsPerPageOptions: [10, 30, 50, 100],
        },"items-per-page":10},scopedSlots:_vm._u([{key:"body",fn:function(ref){
        var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return [_c('tr',{key:item.id,staticClass:"random"},[_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.firstName + " " + item.lastName)+" ")])]),_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(item.email)+" ")])]),_c('td',[_c('a',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-xs"},[_vm._v(" "+_vm._s(_vm.translateRoles(item.roles))+" ")])]),_c('td',[_c('a',{staticClass:"btn btn-light btn-xs mr-2",attrs:{"title":"Edytuj użytkownika"},on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('i',{staticClass:"fa fa-edit p-0 text-primary"})]),_c('a',{staticClass:"btn btn-icon btn-light btn-sm",attrs:{"title":"Usuń użytkownika"},on:{"click":function($event){return _vm.remove(item.id)}}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Trash.svg"}})],1)])])])]})],2)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }