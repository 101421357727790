<template>
  <div class="row">
    <div class="col-xxl-12 order-1 order-xxl-1">
      <router-link
        to="/myCompany/users/add"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
      >
        <b-button :href="href" variant="primary" class="mt-3 mb-4"
          >Dodaj użytkownika</b-button
        >
      </router-link>
      <div class="table-responsive">
        <v-data-table
          dense
          :headers="this.headers"
          :items="this.users"
          class="table custom-table table-head-custom table-vertical-center table-head-bg table-borderless"
          item-key="id"
          mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageText: 'Wyników na stronę',
            pageText: '{0}-{1} na {2}',
            itemsPerPageOptions: [10, 30, 50, 100],
          }"
          :items-per-page="10"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="item in items">
                <tr class="random" :key="item.id">
                  <td>
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ item.firstName + " " + item.lastName }}
                    </a>
                  </td>
                  <td>
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ item.email }}
                    </a>
                  </td>
                  <td>
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ translateRoles(item.roles) }}
                    </a>
                  </td>
                  <td>
                    <a
                      class="btn btn-light btn-xs mr-2"
                      title="Edytuj użytkownika"
                      @click="editItem(item.id)"
                    >
                      <i class="fa fa-edit p-0 text-primary"></i>
                    </a>

                    <a
                      class="btn btn-icon btn-light btn-sm"
                      title="Usuń użytkownika"
                      @click="remove(item.id)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import { USER_LIST } from "../../../core/services/store/user.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Users",
  methods: {
    translateRoles(roles) {
      let availableRoles = {
        clients: "Klienci",
        orders: "Zamówienia",
        lab: "Laboratorium",
        users: "Uzytkownicy",
        analysis: "Analiza sprzedaży",
      };

      return roles.map((role) => availableRoles[role]).join(" | ");
    },
    remove(id) {
      Swal.fire({
        title: "Na pewno usunąć?",
        text: "Na pewno chcesz to zrobić?",
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-primary",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("userDelete", { id });
          Swal.fire("Gotowe!", "Usunięto wpis.", "success");
        }
      });
    },
    editItem(id) {
      this.$router.push("/myCompany/users/" + id + "/edit");
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Moja firma - Użytkownicy", route: "users" },
    ]);

    this.$store.dispatch(USER_LIST);
  },
  computed: {
    ...mapGetters(["users"]),
  },
  data() {
    return {
      headers: [
        {
          text: "Imię i Nazwisko",
          value: "name",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Email",
          value: "email",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Uprawnienia",
          value: "roles",
          style: "min-width: 80px",
          sortable: true,
        },
        {
          text: "Akcja",
          value: "action",
          style: "min-width: 80px",
          sortable: true,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
